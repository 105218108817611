import { render, staticRenderFns } from "./TableLegalStructureTypes.vue?vue&type=template&id=636b9e26"
import script from "./TableLegalStructureTypes.vue?vue&type=script&lang=js"
export * from "./TableLegalStructureTypes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports